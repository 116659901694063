<template>
  <div
    class="
      d-flex
      flex-column
      min-vh-100
      justify-content-center
      align-items-center
      mt-20
    "
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="login-box mb-5">
      <div class="login-box-logo" style="padding-bottom: 20px; width: 100%; display: flex; justify-content: center;">
        <img src="@/assets/images/WEM_logo_nobg.png" alt="Logo WEM" style="max-width: 65%" />
      </div>

      <form @submit.prevent="resetPassword">
        <div class="form-group mb-5 mt-2 col-md-12 text-center">
          <h3
            style="
              font-size: 19.44px;
              font-family: Sofia Pro, Roboto, sans-serif;
              font-weight: 500;
            "
            v-html="$t('reset-password.title')"
          />
          <p
            style="
              font-size: 12.96px;
              font-family: Sofia Pro, Roboto, sans-serif;
              font-weight: 400;
            "
            v-html="$t('reset-password.description')"
          />
        </div>

        <div class="form-group mb-3 col-md-12">
          <!--          <input-->
          <!--            type="password"-->
          <!--            class="form-control"-->
          <!--            :placeholder="$t('reset-password.new-password')"-->
          <!--            v-model="password"-->
          <!--          />-->
          <FormInput
            class="mb-7"
            :label="$t('reset-password.new-password')"
            type="password"
            v-model:model="password"
            :field="password"
          />
        </div>

        <div class="form-group mb-3 col-md-12">
          <!--          <input-->
          <!--            type="password"-->
          <!--            class="form-control"-->
          <!--            :placeholder="$t('reset-password.new-password2')"-->
          <!--            v-model="password2"-->
          <!--          />-->
          <FormInput
            class="mb-7"
            :label="$t('reset-password.new-password2')"
            type="password"
            v-model:model="password2"
            :field="password2"
          />
        </div>

        <div class="form-group mb-3 col-md-12">
          <button
            type="submit"
            class="mt-5 w-full btn primary flex-1"
            style="text-transform: none"
          >
            {{ $t("reset-password.send") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import FormInput from "@/shared/components/FormInput.vue";

export default {
  name: "ResetPassword",
  components: {
    FormInput,
  },
  data() {
    return {
      password: "",
      password2: "",
    };
  },
  methods: {
    getFullTokenFromUrl(url) {
      const urlParts = url.split("/");
      return urlParts[urlParts.length - 1];
    },

    resetPassword() {

      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+\\-])[A-Za-z\d@$!%*?&+\\-]+$/;
      function messageLanguage() {
        if (localStorage.getItem("wem_locale") === "sk") {
          return "Nové heslo musí obsahovať aspoň jedno malé písmeno, jedno veľké písmeno, jednu číslicu a jeden z nasledujúcich symbolov: @$!%*?&+-";
        } else if (localStorage.getItem("wem_locale") === "en") {
          return "The new password must contain at least one lowercase letter, one uppercase letter, one digit, and one of the following symbols: @$!%*?&+-";
        } else {
          return "Новата парола трябва да съдържа поне една малка буква, една голяма буква, една цифра и един от следните символи: @$!%*?&+-";
        }
      }

      if (this.password !== this.password2) {
        this.$store.dispatch(
          `alerts/error`,
          {
            title: "",
            text: this.$t("reset-password.not-match"),
          },
          { root: true }
        );
      } else if (this.password.length < 6) {
        this.$store.dispatch(
          `alerts/error`,
          {
            title: "",
            text: this.$t("reset-password.too-short"),
          },
          { root: true }
        );



      } else if (!passwordRegex.test(this.password)) {
        console.log("Current wem_locale:", localStorage.getItem("wem_locale"));
        const message_en =
          "The new password must contain at least one lowercase letter, one uppercase letter, one digit, and one of the following symbols: @$!%*?&+-";
        const message_sk =
          "Nové heslo musí obsahovať aspoň jedno malé písmeno, jedno veľké písmeno, jednu číslicu a jeden z nasledujúcich symbolov: @$!%*?&+-";
        if (localStorage.getItem("wem_locale") === "sk") {
          this.$store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: message_sk,
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: message_en,
            },
            { root: true }
          );
        }
      } else {
        // console.log("Somtu");
        // const redirectUrl = `${process.env.VUE_APP_URL}/#/login`;
        // window.location.href = redirectUrl;
        var params = new URLSearchParams();
        params.append("token", this.getFullTokenFromUrl(this.$route.fullPath));
        params.append("new_password", this.password);

        this.$store
          .dispatch("onboarding/changeForgotPassword", {
            params,
          })
          .then(() => {
            const redirectUrl = `${process.env.VUE_APP_URL}/#/login`;
            window.location.href = redirectUrl;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  &-logo {
    width: 100%;

    img {
      width: 100%;
    }
  }

  width: 450px;
  background: #ece6e6;
  padding: 50px;
  max-width: 100%;
}
</style>
